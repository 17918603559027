// @ts-nocheck
import { wrapIcon } from "./wrapIcon";
import { default as Activity } from "./activity.svg";
export const IconActivity = wrapIcon(Activity);
import { default as ArrowRight } from "./arrow-right.svg";
export const IconArrowRight = wrapIcon(ArrowRight);
import { default as Bell } from "./bell.svg";
export const IconBell = wrapIcon(Bell);
import { default as Check } from "./check.svg";
export const IconCheck = wrapIcon(Check);
import { default as ChevronDown } from "./chevron-down.svg";
export const IconChevronDown = wrapIcon(ChevronDown);
import { default as ChevronLeft } from "./chevron-left.svg";
export const IconChevronLeft = wrapIcon(ChevronLeft);
import { default as ChevronRight } from "./chevron-right.svg";
export const IconChevronRight = wrapIcon(ChevronRight);
import { default as ChevronUp } from "./chevron-up.svg";
export const IconChevronUp = wrapIcon(ChevronUp);
import { default as Edit } from "./edit.svg";
export const IconEdit = wrapIcon(Edit);
import { default as Facebook } from "./facebook.svg";
export const IconFacebook = wrapIcon(Facebook);
import { default as GoogleColor } from "./google-color.svg";
export const IconGoogleColor = wrapIcon(GoogleColor);
import { default as Headphones } from "./headphones.svg";
export const IconHeadphones = wrapIcon(Headphones);
import { default as Home } from "./home.svg";
export const IconHome = wrapIcon(Home);
import { default as List } from "./list.svg";
export const IconList = wrapIcon(List);
import { default as LogIn } from "./log-in.svg";
export const IconLogIn = wrapIcon(LogIn);
import { default as Logo } from "./logo.svg";
export const IconLogo = wrapIcon(Logo);
import { default as MapPin } from "./map-pin.svg";
export const IconMapPin = wrapIcon(MapPin);
import { default as Menu } from "./menu.svg";
export const IconMenu = wrapIcon(Menu);
import { default as MessageSquare } from "./message-square.svg";
export const IconMessageSquare = wrapIcon(MessageSquare);
import { default as MoreHorizontal } from "./more-horizontal.svg";
export const IconMoreHorizontal = wrapIcon(MoreHorizontal);
import { default as MoreVertical } from "./more-vertical.svg";
export const IconMoreVertical = wrapIcon(MoreVertical);
import { default as MusicX } from "./music-x.svg";
export const IconMusicX = wrapIcon(MusicX);
import { default as Music } from "./music.svg";
export const IconMusic = wrapIcon(Music);
import { default as Pause } from "./pause.svg";
export const IconPause = wrapIcon(Pause);
import { default as PlayListAdd } from "./play-list-add.svg";
export const IconPlayListAdd = wrapIcon(PlayListAdd);
import { default as Play } from "./play.svg";
export const IconPlay = wrapIcon(Play);
import { default as PlusSquare } from "./plus-square.svg";
export const IconPlusSquare = wrapIcon(PlusSquare);
import { default as Plus } from "./plus.svg";
export const IconPlus = wrapIcon(Plus);
import { default as Reddit } from "./reddit.svg";
export const IconReddit = wrapIcon(Reddit);
import { default as Search } from "./search.svg";
export const IconSearch = wrapIcon(Search);
import { default as Send } from "./send.svg";
export const IconSend = wrapIcon(Send);
import { default as Settings } from "./settings.svg";
export const IconSettings = wrapIcon(Settings);
import { default as Share2 } from "./share-2.svg";
export const IconShare2 = wrapIcon(Share2);
import { default as SkipBack } from "./skip-back.svg";
export const IconSkipBack = wrapIcon(SkipBack);
import { default as SkipForward } from "./skip-forward.svg";
export const IconSkipForward = wrapIcon(SkipForward);
import { default as Spotify } from "./spotify.svg";
export const IconSpotify = wrapIcon(Spotify);
import { default as Twitter } from "./twitter.svg";
export const IconTwitter = wrapIcon(Twitter);
import { default as UserPlus } from "./user-plus.svg";
export const IconUserPlus = wrapIcon(UserPlus);
import { default as User } from "./user.svg";
export const IconUser = wrapIcon(User);
import { default as Users } from "./users.svg";
export const IconUsers = wrapIcon(Users);
import { default as X } from "./x.svg";
export const IconX = wrapIcon(X);
import { default as Youtube } from "./youtube.svg";
export const IconYoutube = wrapIcon(Youtube);
import { default as Zap } from "./zap.svg";
export const IconZap = wrapIcon(Zap);
